import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
// import PropTypes from "prop-types";

// Custom Components for Markdown
const CustomH1 = ({ children }) => <h1 className="markdown-h1">{children}</h1>;
const CustomH2 = ({ children }) => <h2 className="markdown-h2">{children}</h2>;
const CustomH3 = ({ children }) => <h3 className="markdown-h3">{children}</h3>;
const CustomH4 = ({ children }) => <h4 className="markdown-h4">{children}</h4>;
const CustomH5 = ({ children }) => <h5 className="markdown-h5">{children}</h5>;
const CustomH6 = ({ children }) => <h6 className="markdown-h6">{children}</h6>;
const CustomUL = ({ children }) => <ul className="markdown-ul">{children}</ul>;
const CustomOL = ({ children }) => <ol className="markdown-ol">{children}</ol>;
const CustomLI = ({ children }) => <li className="markdown-li">{children}</li>;

const MarkdownRenderer = ({ message }) => {
    console.log("message", message)
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: CustomH1,
        h2: CustomH2,
        h3: CustomH3,
        h4: CustomH4,
        h5: CustomH5,
        h6: CustomH6,
        ul: CustomUL,
        ol: CustomOL,
        li: CustomLI,
      }}
    >
      {message}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;

import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import ChatWidget from './ChatWidget.js';
import './App.css';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <ChatWidget />
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
